/* Import the base Shoelace stylesheet: */
@import "@shoelace-style/shoelace/dist/themes/dark.css";

:root {
  --body-background: #0D1117;
  --body-color: #b7c2cc;
  --heading-color: #ddecfc;
  --action-color: #62abff;
  --button-text-color: #000;
  --sl-input-placeholder-color: var(--sl-color-neutral-700);
  --sl-input-placeholder-color-disabled: var(--sl-color-neutral-400);
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 0 8px;
  font-size: 1.3rem;
  line-height: 1.5;
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: #849fbf;//#88a6fa;
}

h1 {
  margin: 1rem 0 1rem;
  text-align: center;
  font-weight: 900;
  font-size: 2.5rem;  
  line-height: 1.2;
}

h2 {
  margin: 1rem 0 1rem;  
}

h3 {
  font-size: 1.4rem;  
}

h4, h5, h6 {
  margin: 1rem 0 0.2rem;
  line-height: 1.2;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--heading-color);
}

body > header {
  margin: 3rem 1rem 1rem 1rem;
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body > nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body > nav a {
  text-decoration: none;
}

main {
  margin: 2rem auto 4rem;
  max-width: 65rem;
  min-height: calc(100vh - 200px);
  padding: 25px 25px 50px;
  background: #161B22;
  border: 1px solid #34373b;
  border-radius: 3px;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

main > p {
  margin: 0.2rem 0 1rem; 
  line-height: 1.1;
}

main .posts li {
  margin: 2rem auto 2rem;
  font-size: 1.3rem;
  font-weight: bold;
}

footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
}

hr {
  border: none;
  border-top: 2px solid #bbb;
  margin: 3rem 0;
}

pre {
  overflow: auto;
}

sl-button.signup::part(base) {
  background: var(--action-color);
  color: var(--button-text-color);
  font-size: 1.3rem;
  font-weight: var(--sl-font-weight-bold);
}
